.NarrowFrame {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;

  @media screen and (min-width: 768px) {
    box-shadow: 7px 7px 22px rgba(0, 0, 0, .7), inset 4px 8px 20px rgba(0, 0, 0, .6);
    border: 25px solid #404040;
    border-radius: 2px;
    height: 100%;
    background-color: #ededed;
    padding: 40px;
  }

  &--content {
    position: relative;
    box-sizing: border-box;
    background: #ffffff;
    padding: 0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .3);

    @media screen and (min-width: 768px) {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: none;

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        box-shadow: inset 2px 2px 4px rgba(0, 0, 0, .3);
      }
    }

    > img {
      width: 100%;
      height: 100%;
    }

    &.center {
      align-items: center;
      justify-content: center;
    }
  }
}