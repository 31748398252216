.TimelineItem {
  list-style: none;
  margin: 3em 0;
  padding: 0 0 0 2em;
  position: relative;
  color: #47423b;

  &.reveal {
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -14px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: none repeat scroll 0% 0% #47423b;
      border: 4px solid #f2f2f2;
      animation: timelineRevealScaleUp 0.25s 0s ease both 1;
    }

    .TimelineItem--content {
      animation: timelineRevealFromLeft 0.5s 0s ease both 1;
    }

    &:nth-child(1):before {
      animation-delay:0.85s;
    }
    &:nth-child(1) .TimelineItem--content {
      animation-delay:1.05s;
    }
    &:nth-child(2):before {
       animation-delay:1.2s;
    }
    &:nth-child(2) .TimelineItem--content {
      animation-delay:1.5s;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &--date {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: .5em;
  }

  &--org {
    font-size: 13px;
    font-weight: 500;
    color: #7c7c7c;
    margin: 0;
  }

  &--position {
    font-size: 14px;
    margin: .5em 0;
    font-weight: 500;
  }

  &--desc {
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5;
  }
};

@keyframes timelineRevealFromLeft {
  0% {
    transform:translateX(-1em);
    opacity:0;
  }
  100% {
    opacity:1;
    transform:translateX(0);
  }
}

@keyframes timelineRevealScaleUp {
  0% {
    opacity:0;
    transform:scale(0.5);
  }
  
  50% {
     opacity:1;
     transform:scale(1.3);
    
  }
  100% {
    opacity:1;
    transform:scale(1);
    
  }
}