.Container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  //min-height: 100vh;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 60px;
  }

  &.centered {
    display: flex;
    align-items: center;
  }

  &.flexEnd {
    justify-content: flex-end;
  }
}