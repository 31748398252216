.WideFrame {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  @media screen and (min-width: 768px) {
    border: 35px solid black;
    padding: 60px;
    border-radius: 2px;
    box-shadow: 7px 7px 22px rgba(0, 0, 0, .7), inset 4px 8px 20px rgba(0, 0, 0, .6);
  }

  @media screen and (min-width: 1024px) {
    max-height: 750px;
    max-width: 550px;
  }

  &-white {
    border-color: #f4f1f2;
    background-color: #ededed;

    .WideFrame--content {
      background: #fafafa;
      color: #47423b;
    }
  }

  &--content {
    box-sizing: border-box;
    background: linear-gradient(140deg, #121212 10%, #000000);
    color: #ffffff;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 30px;

    @media screen and (min-width: 768px) {
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, .3);
    }
  

    &.centered {
      align-items: center;
      justify-content: center;
    }
  }
}