.SkillDots {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  perspective: 0;
  
  &.rotate {
    .SkillDots--item {
      &--back {
        backface-visibility: hidden;
        background-color: #47423b;
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        transform: rotateY( 180deg );
      }
  
      &.active {
        transform-style: preserve-3d;
        animation: rotate 1.6s ease-in-out forwards;
  
        &:nth-child(1) {
          animation-delay: .3s;
        }
        &:nth-child(2) {
          animation-delay: .4s;
        }
        &:nth-child(3) {
          animation-delay: .5s;
        }
        &:nth-child(4) {
          animation-delay: .6s;
        }
        &:nth-child(5) {
          animation-delay: .7s;
        }
        &:nth-child(6) {
          animation-delay: .8s;
        }
        &:nth-child(7) {
          animation-delay: .9s;
        }
        &:nth-child(8) {
          animation-delay: 1s;
        }
      }
    }
  }

  &--item {
    position: relative;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #c9c8c9;
    margin-left: .25em;

    &:first-child {
      margin: 0;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotateY( 180deg );
  }
}