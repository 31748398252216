.UnderlinedTitle {
  position: relative;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  margin: 0 0 2em 0;
  
  &::after {
    content: "";
    width: 90%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-image: linear-gradient(90deg, #47423b, #47423b 2px, transparent 2px, transparent 6px);
    background-size: 6px 2px;
  }
}