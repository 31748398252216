.About {
  box-sizing: border-box;
  padding: 30px;  
  display: grid;
  //grid-row: 1fr;
  gap: 50px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}