.Link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: #47423b;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }

  > img {
    margin-right: .5em;
    height: 20px;
  }
}